.tile {
  width: 20rem !important;
}

.shadow-inset-default {
  box-shadow: 3px 1px 73px -5px rgba(220, 220, 220, 0.25) inset;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-default {
  cursor: default !important;
}

.no-outline {
  outline: none !important;
}

.row {
  margin-right: 0;
  margin-left: 0;
}


.sidebar {
  i:before {
    color: white !important;
    font-size: 2.5rem;
  }
}

i[class^="n4icon"] {
  outline: none;
  font-size: 2rem;
  cursor: pointer;

  &:before {
    color: $dark-grey-black;
  }
}

form {

  .textareaContainer {
    min-height: 2rem;
    display: block
  }

  textarea {
    height: 100%;
    width: 100%;
    outline: none;

    &::placeholder {
      color: $dark-grey-white-7 !important;
      font-style: italic;
      font-size: 90%;
    }

    &:disabled {
      color: darken(#f6f6f6, 12%);
      background-color: #f6f6f6 !important;
    }
  }

  input, select {
    &:disabled {
      color: darken(#f6f6f6, 12%);
      background-color: #f6f6f6 !important;
      pointer-events: none;
    }

    &.ng-untouched.ng-invalid ~ .show-required-star {
      &:after {
        color: $danger;
        position: absolute;
        top: -3px;
        right: 10px;
        content: "*";
      }
    }

    &.ng-touched.ng-invalid,
    .ng-dirty.ng-invalid {
      border-left: 2px solid $danger;
    }

    &.ng-touched.ng-valid,
    .ng-dirty.ng-valid {
      border-left: 2px solid $success;
    }
  }
}


.center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.data-box {
  // set default color icons in drag-list minus red, plus green
  .n4icon-plus-add-outline:before {
    color: $success !important;
  }


  .n4icon-minus-outline:before {
    color: $danger !important;
  }

  .n4icon-edit-pen-outline:hover {
    &:before {
      color: $turquoise !important;
    }
  }

  .n4icon-trash-outline:hover {
    &:before {
      color: $danger !important;
    }
  }
}

.tile {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem !important;
  background: #fff !important;
  box-shadow: 0px 1px 5px #00000029 !important;
  border: none !important;

  &:hover {
    box-shadow: none;
  }

  .text-no-image {
    span {
      line-height: 100%;
    }
  }

}


.info-text {
  font-size: 120%;
  font-weight: bolder;
  color: $turquoise;
  position: relative;
  border-radius: .25rem;
  margin: 2rem 0rem;

  i[class^="n4icon"] {
    outline: none;
    font-size: 2rem;
    cursor: pointer;

    &:before {
      color: $turquoise;
    }
  }
}


.empty-element {
  cursor: default;
  margin: auto;
  color: $dark-grey-white-4;
}

.button-group {
  display: flow-root;
  margin: 1rem 0;
}


.card-body {
  margin: 1rem;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 40%;
}

.confirm-modal {
  button {
    outline: none !important;
  }
}

.custom-modal {
  animation-name: smooth;
  animation-duration: 0.3s;
}

@keyframes smooth {
  0% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1)
  }
}

.state-list-item:hover {
  .dot-default {
    background-color: $primary;
  }
}


.modal-dialog {
  min-width: 750px;
  margin-left: -5rem;
}

.dropdown-menu.show {
  z-index: 1050;
}


.font-size-1rem {
  font-size: 1rem !important;
}


ngb-typeahead-window.dropdown-menu {
  max-height: 500px !important;
  overflow-y: auto !important;
}


@media print {
  .button-group, .no-print {
    display: none !important;
  }

  .container-fluid {
    overflow: unset !important;
  }
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #f2f2f2 #3c3c3b;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: #3c3c3b;
}

*::-webkit-scrollbar-thumb {
  background-color: #f2f2f2;
  border-radius: 10px;
}
