.drag-drop {
  cursor: move;
}

.list-item {

  &.affirm {
    background-color: rgba($turquoise, .3);
  }

  &.inactive {
    color: $dark-grey-white-5;

    i[class^="n4icon"] {
      &:before {
        color: $dark-grey-white-5;
      }
    }
  }
}

.cdk-drag-preview {
  height: 5rem;
  line-height: 5rem;
  border: 1px solid $turquoise !important;
  background-color: $white;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
