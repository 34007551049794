@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900');

$fallback: sans-serif;
$main-font: 'Source Sans Pro', $fallback;
$font-size: 12px;
$font-weight: 400;


html, body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: $font-size;
}


label {
  font-size: 12px;
}

button, input, select, textarea {
  font-size: 14px;
}

h2 {
  font-size: 2.25rem;
  font-weight: bold;
}
