.new-material-code-modal {
  .modal-dialog {
    top: 15%;
    ol {
      max-height: 500px;
      overflow-y : auto;
      padding-left: 2rem;
      margin-top: 1rem;
    }
  }
}
