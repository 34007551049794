.user-login-container {
  $border-width: 2px;
  $corner-size: 15px;
  $duration: 0.2s;
  height: 100vh;
  background: url(~assets/images/background.png) no-repeat center center fixed;
  background-size: cover;
  overflow: auto;


  .sidebar {
    position: absolute;
    height: 100%;
    width: 15px;
    left: 0;
    background: linear-gradient(to bottom, #3bb8b4 0%, #3bb8b4 16.66%, #008580 16.66%, #008580 33.32%, #bbd6b5 33.22%, #bbd6b5 49.98%, #f8c135 49.98%, #f8c135 66.64%, #fa7800 66.64%, #fa7800 83.33%, #bd4b11 83.33%, #bd4b11 100%) !important;
  }

  .login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 450px;
    transform: translate(-50%, -50%);
    background: $white;
    box-sizing: border-box;
    box-shadow: $box-shadow;
    border-radius: 2px;

    .sidebar {
      width: 5px;
    }

    .user-box {
      padding: 25px;
    }

    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

    }
  }
}


